export const DEGREE_TYPE_LIST = ['bachelor', 'graduate', 'doctor'] as const;
export const DEGREE_TYPE_NAME = {
  bachelor: '学士',
  graduate: '修士',
  doctor: '博士',
} as const;

export type DegreeType = 'bachelor' | 'graduate' | 'doctor';
export type DegreeTypeExcludeDoctor = Exclude<DegreeType, 'doctor'>;

export const BACHELOR_GRADE_ID_LIST: number[] = [1, 2, 3, 4, 5, 6] as const;
export const MASTER_GRADE_ID_LIST: number[] = [7, 8] as const;
export const DOCTOR_GRADE_ID_LIST: number[] = [9] as const;

export const UNIVERSITY_TYPE_LIST = ['university', 'kosen', 'foreign'] as const;
export type UniversityType = 'university' | 'kosen' | 'foreign';

export type BunriNameType = 'humanity' | 'science';
export type BunriNumberType = 1 | 2;
export const BUNRI_TYPE_LIST = [1, 2] as const;
export const BUNRI_DICTIONARY_TO_NUMBER: Record<
  BunriNameType,
  BunriNumberType
> = {
  humanity: 1,
  science: 2,
} as const;

import {z} from 'zod';

export const USER_TYPE_REGULAR = 'regular';
export const USER_TYPE_LIGHT = 'light';
export const USER_TYPE_COMPANY = 'company';

export const SCOUT_STATUS_UNREAD = 'unread';
export const SCOUT_STATUS_READ = 'read';
export const SCOUT_STATUS_ACCEPTED = 'accepted';
export const SCOUT_STATUS_REJECT = 'reject';
export const SCOUT_STATUS_NOREPLY = 'noReply';

export const SCOUT_SORT_NEW = 'new';
export const SCOUT_SORT_DEADLINE = 'deadline';

export const MESSAGE_TYPE_TEXT = 'text';

export const ScoutStatus = {
  unread: SCOUT_STATUS_UNREAD,
  read: SCOUT_STATUS_READ,
  accepted: SCOUT_STATUS_ACCEPTED,
  reject: SCOUT_STATUS_REJECT,
  noReply: SCOUT_STATUS_NOREPLY,
};

export const ScoutSort = {
  new: SCOUT_SORT_NEW,
  deadline: SCOUT_SORT_DEADLINE,
} as const;

export const ScoutStatusForFilter = {
  ...ScoutStatus,
} as const;

export const MessageTypes = {
  text: MESSAGE_TYPE_TEXT,
} as const;

export const UserTypes = {
  regular: USER_TYPE_REGULAR,
  light: USER_TYPE_LIGHT,
  company: USER_TYPE_COMPANY,
} as const;

export const ScoutSortEnum = z.nativeEnum(ScoutSort);
export const ScoutStatusEnum = z.nativeEnum(ScoutStatus);
export const UserTypesEnum = z.nativeEnum(UserTypes);
export const MessageTypesEnum = z.nativeEnum(MessageTypes);

import type {FC} from 'react';
import {MdCheckBoxOutlineBlank, MdCheckBox} from 'react-icons/md';

export type CheckboxButtonProps = {
  isSelected: boolean;
};

export const CheckboxButton: FC<CheckboxButtonProps> = ({
  isSelected = false,
}) => {
  return isSelected ? (
    <div className="mr-8">
      <MdCheckBox fill="#1E83B9" size={20} />
    </div>
  ) : (
    <div className="mr-8">
      <MdCheckBoxOutlineBlank fill="#1E83B9" size={20} />
    </div>
  );
};

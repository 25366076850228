import React from 'react';

export type WhiteBgButtonProps = {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  isPrimary?: boolean;
  isLarge?: boolean;
  isDisabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export type WhiteBgButtonThinProps = {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  isPrimary?: boolean;
  isDisabled?: boolean;
  className?: string;
  onClick?: () => void;
};

const getColorClass = (
  isPrimary: boolean,
  isDisabled: boolean,
): {bgClass: string; textColorClass: string} => {
  if (isDisabled) {
    return {
      bgClass: 'bg-disabled-1 pointer-events-none',
      textColorClass: 'text-black-4',
    };
  }

  return isPrimary
    ? {
        bgClass:
          'bg-white border-accent-1 pc:hover:border-accent-2 pc:hover:ease-in-out pc:hover:duration-300',
        textColorClass:
          'text-accent-1 pc:group-hover:text-accent-2 pc:hover:ease-in-out pc:hover:duration-300',
      }
    : {
        bgClass:
          'bg-white border-primary-1 pc:hover:border-primary-2 pc:hover:ease-in-out pc:hover:duration-300',
        textColorClass:
          'text-primary-1 pc:group-hover:text-primary-2 pc:hover:ease-in-out pc:hover:duration-300',
      };
};

export const WhiteBgButton: React.FC<WhiteBgButtonProps> = ({
  label,
  type = 'button',
  isPrimary = true,
  isLarge = true,
  isDisabled = false,
  className = '',
  onClick = undefined,
}) => {
  const {bgClass, textColorClass} = getColorClass(isPrimary, isDisabled);
  const textSizeClass: string = isLarge ? 'text-lg' : 'text';
  const paddingClass: string = isLarge ? 'py-[9px]' : 'py-[10px]';
  return (
    <button
      className={`${bgClass} ${paddingClass} ${className} rounded-4 shadow-bottom group flex min-w-[100px] items-center justify-between border border-solid`}
      type={type}
      onClick={onClick}
    >
      <span
        className={`${textSizeClass} ${textColorClass} leading block w-full text-center font-bold`}
      >
        {label}
      </span>
    </button>
  );
};

export const WhiteBgButtonThin: React.FC<WhiteBgButtonThinProps> = ({
  label,
  type = 'button',
  isPrimary = true,
  isDisabled = false,
  className = '',
  onClick = undefined,
}) => {
  const {bgClass, textColorClass} = getColorClass(isPrimary, isDisabled);
  return (
    <button
      className={`${bgClass} ${className} rounded-4 shadow-bottom group flex min-w-[100px] items-center justify-between border border-solid py-[7px]`}
      type={type}
      onClick={onClick}
    >
      <span
        className={`${textColorClass} leading block w-full text-center text-sm font-normal`}
      >
        {label}
      </span>
    </button>
  );
};

import React from 'react';
import {Box, Text} from '@mantine/core';
import {MdClose, MdErrorOutline} from 'react-icons/md';
import {
  COLOR_PRIMARY_1,
  COLOR_SUCCESS_1,
} from '../../../config/constants/colorCodes';
import {
  URL_TO_ANDROID_APP_WEB,
  URL_TO_iOS_APP_WEB,
} from '../../../config/constants/routes';
import {useMediaQuery} from '../../../shared/services/useMediaQuery';

type Props = {
  closePopup: () => void;
};

export const MobilePromo: React.FC<Props> = ({closePopup}) => {
  const {isPC} = useMediaQuery();
  return (
    <>
      {isPC ? (
        <Box className="flex flex-row justify-between">
          <Box className="flex flex-row gap-x-4">
            <MdErrorOutline size={18} color={COLOR_SUCCESS_1} />
            <Box className="text-base text-black-1 flex flex-row">
              外資就活ドットコムの
              <Text
                className="text-primary-2 cursor-pointer"
                onClick={() => window.open(URL_TO_iOS_APP_WEB)}
              >
                iPhoneアプリ
              </Text>
              、
              <Text
                className="text-primary-2 cursor-pointer"
                onClick={() => window.open(URL_TO_ANDROID_APP_WEB)}
              >
                Androidアプリ
              </Text>
              を使って誰よりも早く最新情報をGETしよう。
            </Box>
          </Box>
          <Box
            className="flex flex-row text-primary-2 items-center text-base cursor-pointer"
            onClick={closePopup}
          >
            <MdClose size={14} />
            <Text>閉じる</Text>
          </Box>
        </Box>
      ) : (
        <Box className="flex flex-row justify-between">
          <Box className="flex flex-row gap-x-4 text-black-1 items-center">
            <MdErrorOutline size={18} color={COLOR_PRIMARY_1} />
            <Box className="text-sm flex">
              <Text
                className="text-primary-1 cursor-pointer"
                onClick={() => window.open(URL_TO_iOS_APP_WEB)}
              >
                iPhone
              </Text>
              、
              <Text
                className="text-primary-1 cursor-pointer"
                onClick={() => window.open(URL_TO_ANDROID_APP_WEB)}
              >
                Android
              </Text>
              アプリで最新情報をGETしよう！
            </Box>
          </Box>
          <MdClose size={14} color={COLOR_PRIMARY_1} onClick={closePopup} />
        </Box>
      )}
    </>
  );
};

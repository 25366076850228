import {useCallback, useState} from 'react';

export const useBooleanState = (initialValue: boolean) => {
  const [state, setState] = useState(initialValue);
  const setTrue = useCallback(() => {
    setState(true);
  }, []);
  const setFalse = useCallback(() => {
    setState(false);
  }, []);
  const toggle = useCallback(() => {
    setState((previous) => !previous);
  }, []);

  return [state, setTrue, setFalse, toggle] as const;
};

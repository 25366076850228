export function createPathWithQuery(
  path: string,
  parameters: Record<string, string | number | string[] | number[] | boolean>,
  bracketsPosition = 'before',
): string {
  const searchParameters = new URLSearchParams();
  for (const k of Object.keys(parameters)) {
    const parameter = parameters[k];
    if (typeof parameter === 'string') {
      searchParameters.append(k, parameter);
      continue;
    }

    if (typeof parameter === 'number') {
      searchParameters.append(k, parameter.toString());
      continue;
    }

    if (typeof parameter === 'boolean') {
      searchParameters.append(k, parameter.toString());
      continue;
    }

    if (Array.isArray(parameter)) {
      switch (bracketsPosition) {
        case 'before': {
          for (const p of parameter) {
            searchParameters.append(`[]${k}`, p.toString());
          }

          break;
        }

        case 'after': {
          for (const p of parameter) {
            searchParameters.append(`${k}[]`, p.toString());
          }

          break;
        }

        case 'none': {
          for (const p of parameter) {
            searchParameters.append(k, p.toString());
          }

          break;
        }

        default: {
          throw new Error('Invalid bracketsPosition');
        }
      }
    }
  }

  return `${path}?${searchParameters.toString()}`;
}

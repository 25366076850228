import React from 'react';

export type QuestionButtonProps = {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  isTextSmall?: boolean;
  onClick?: () => void;
};

export const QuestionButton: React.FC<QuestionButtonProps> = ({
  label,
  type = 'button',
  className,
  isTextSmall = false,
  onClick,
}) => {
  return (
    <button
      type={type}
      className={`${
        className ? className : ''
      } bg-primary-1 rounded-4 shadow-bottom flex w-full min-w-[100px] items-center justify-between py-[10px] hover:opacity-80`}
      onClick={onClick}
    >
      <span
        className={`text-white ${
          isTextSmall ? 'text' : 'text-lg'
        }  leading block w-full text-center font-bold`}
      >
        {label}
      </span>
    </button>
  );
};

export const modalConfig = {
  withCloseButton: false,
  centered: true,
  padding: 0,
  overlaycolor: '#000000',
  overlayopacity: 0.48,
};

export const overlayConfig = {
  overlaycolor: '#000000',
  overlayopacity: 0.48,
};

import React, {useState} from 'react';
import {Box, Text} from '@mantine/core';
import {MdClose} from 'react-icons/md';
import {MYPAGE_SCOUT_SETTING} from '../../../config/constants/routes';
import {useMediaQuery} from '../../../shared/services/useMediaQuery';
import {COLOR_PRIMARY_1} from '../../../config/constants/colorCodes';
import {ScoutModal} from './Container/ScoutModal';

type Props = {
  closePopup: () => void;
  userName?: string;
};
export const ScoutPromo: React.FC<Props> = ({closePopup, userName}) => {
  const handleSCoutSetting = () => {
    location.href = MYPAGE_SCOUT_SETTING;
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const {isPC} = useMediaQuery();
  return (
    <>
      {isPC ? (
        <Box className="flex flex-row justify-between">
          <Box className="flex flex-row gap-x-4">
            <Box className="text-base text-black-1 flex flex-row">
              {userName}さんは、
              <Text
                className="text-primary-2 cursor-pointer"
                onClick={() => {
                  setIsOpenModal(true);
                }}
              >
                Premiumスカウト
              </Text>
              未登録です。優良企業に見つけてもらうために、
              <Text
                className="text-primary-2 cursor-pointer"
                onClick={handleSCoutSetting}
              >
                まずは登録
              </Text>
              しましょう！
            </Box>
          </Box>
          <Box
            className="flex flex-row text-primary-2 items-center text-base cursor-pointer"
            onClick={closePopup}
          >
            <MdClose size={14} />
            <Text>閉じる</Text>
          </Box>
          <ScoutModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
          />
        </Box>
      ) : (
        <Box className="flex flex-row justify-between text-sm">
          <Box className="flex flex-row gap-x-4 text-black-1 items-center">
            <Box className="text-sm  flex flex-row">
              <Text
                className="text-primary-1 cursor-pointer"
                onClick={() => {
                  setIsOpenModal(true);
                }}
              >
                Premiumスカウト
              </Text>
              に、
              <Text
                className="text-primary-2 cursor-pointer"
                onClick={handleSCoutSetting}
              >
                登録
              </Text>
              しましょう！
            </Box>
          </Box>
          <Box
            className="flex flex-row text-primary-1 items-center"
            onClick={closePopup}
          >
            <MdClose size={14} />
          </Box>
          <ScoutModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
          />
        </Box>
      )}
    </>
  );
};

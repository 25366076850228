import type {FC} from 'react';
import {checkPathForPopup} from '../services/checkPathForPopup';
import {GeneralHeader} from './Header/GeneralHeader';
import {Popup} from './Header/Popup';

type Props = {
  className?: string;
};

export const Header: FC<Props> = ({className}) => {
  return (
    <div className={className}>
      <GeneralHeader />
      {checkPathForPopup() && <Popup />}
    </div>
  );
};

export const GENDER_TYPE_LIST = ['man', 'woman'] as const;

/**
 *  1:男性 2:女性
 *
 * Memo: 社会文化的な観点から性別について考えある特定の役割や行動に言及する時はgenderを使い、生物学的な観点から性別を検討した場合はsexを使います
 */
export const SEX = {
  MEN: 1,
  WOMEN: 2,
};

/* 言語 */
export const TOEIC = {
  MIN: 0,
  MAX: 990,
};
export const TOEFL_TYPE_LIST = ['iBT', 'ITP'] as const;
export const TOEFL_TYPE = {
  IBT: 'iBT',
  ITP: 'ITP',
} as const;
export const TOEFL_TYPE_SELECT_LIST = [
  {label: TOEFL_TYPE.IBT, value: TOEFL_TYPE.IBT},
  {label: TOEFL_TYPE.ITP, value: TOEFL_TYPE.ITP},
] as const;
export type ToeflType = (typeof TOEFL_TYPE_LIST)[number];

export const TOEFL_IBT = {
  MIN: 0,
  MAX: 120,
};
export const TOEFL_ITP = {
  MIN: 0,
  MAX: 677,
};
export const HITOKOTO_PR_MESSAGE_LENGTH = 20;

export const LANGUAGE_LEVEL_SELECT_LIST = [
  {label: '日常会話レベル', value: '1'},
  {label: 'ビジネス会話レベル', value: '2'},
  {label: 'ネイティブレベル', value: '3'},
];

import {
  type AppError,
  InvalidTokenError,
  type Result,
  type ResultWithOauthToken,
} from '@packages/utils';
import {refreshAccesstoken} from '../../repositories/sessionRepository';
import {DEFAULT_API_VERSION} from '../../../config/apiVersion';
import {cookieApiRefreshTokenKey} from '../../../server/token';
import {
  setAccessTokenToCookie,
  setRefreshTokenToCookie,
} from './setOauthTokenToCookie';
import {sendOauthTokenToRNApp} from './sendOauthTokenToRNApp';

export type HandleRefreshAccessTokenArgs<T> = {
  cookies: Partial<Record<string, string>>;
  request: (accessToken: string) => Promise<Result<T, AppError>>; // 元のリクエストを再実行する関数
  version?: string;
};

export const SESSION_EXPIRED_RETURN_VALUE = {
  ok: false,
  error: InvalidTokenError,
} as const;

// Tは元のリクエストの戻り値の型
export const handleRefreshAccessToken = async <T>({
  cookies,
  request,
  version = DEFAULT_API_VERSION,
}: HandleRefreshAccessTokenArgs<T>): Promise<
  ResultWithOauthToken<T, AppError>
> => {
  const refreshToken = cookies[cookieApiRefreshTokenKey];
  if (!refreshToken) {
    return SESSION_EXPIRED_RETURN_VALUE;
  }

  const UpdateAccessTokenResponse = await refreshAccesstoken({
    refreshToken,
    version,
  });
  if (!UpdateAccessTokenResponse.ok) {
    return SESSION_EXPIRED_RETURN_VALUE;
  }

  const {accessToken: newAccessToken, refreshToken: newRefreshToken} =
    UpdateAccessTokenResponse.value;

  if (typeof window !== 'undefined') {
    // 新しいAccessTokenとRefreshTokenをCookieに保存する
    // サーバーサイドでは以下の関数が使えないため別で対応する
    setAccessTokenToCookie({accessToken: newAccessToken});
    setRefreshTokenToCookie({refreshToken: newRefreshToken});
  }

  // 新しいAccessTokenとRefreshTokenをアプリ側に送信する
  if (typeof window !== 'undefined' && (window as any).ReactNativeWebView) {
    sendOauthTokenToRNApp({
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    });
  }

  // 新しいAccessTokenを使ってリクエストを再実行する
  const newRequestResult = await request(newAccessToken);
  return {
    ...newRequestResult,
    accessToken: newAccessToken,
    refreshToken: newRefreshToken,
  };
};

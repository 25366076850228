export function baseUrl(): string {
  let url: string | undefined;
  // If server-side
  if (typeof window === 'undefined') {
    url = process.env.API_V2_ENDPOINT;
    if (url === undefined) {
      throw new TypeError('API_V2_ENDPOINT is not defined correctly');
    }
  } else {
    url = process.env.NEXT_PUBLIC_API_V2_ENDPOINT;
    if (url === undefined) {
      throw new TypeError(
        'NEXT_PUBLIC_API_V2_ENDPOINT is not defined correctly',
      );
    }
  }

  return url;
}

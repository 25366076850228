import {useContext} from 'react';
import {ClientContext} from './ClientContext';

export function useClient() {
  const context = useContext(ClientContext);
  const get = context.get.bind(context);
  const post = context.post.bind(context);
  const put = context.put.bind(context);
  return {
    get,
    post,
    put,
  };
}

import Link from 'next/link';
import isAppWebView from '../../config/isAppWebView';

export const CommonLink: React.FC<{
  children: React.ReactNode;
  href: string;
  className?: string;

  options?: any;
}> = ({children, href, className, options}) => {
  if (isAppWebView()) {
    return (
      <a href={href} className={className} {...options}>
        {children}
      </a>
    );
  }

  return (
    <Link href={href} className={className} {...options}>
      {children}
    </Link>
  );
};

import React from 'react';
import {Box, Modal, Text, Image} from '@mantine/core';
import {MdWarning} from 'react-icons/md';
import {GMAIL_PC_IMAGE, GMAIL_PC_MBOX} from '../../../../config/constants/img';
import {COLOR_NOTIFICATION} from '../../../../config/constants/colorCodes';

type Props = {
  isOpenModal: boolean;
  setIsOpenModal: (isOpen: boolean) => void;
};

export const GmailModalPc: React.FC<Props> = ({
  isOpenModal,
  setIsOpenModal,
}) => {
  return (
    <Modal
      opened={isOpenModal}
      onClose={() => {
        setIsOpenModal(false);
      }}
      withCloseButton
      className="h-[90%] relative m-auto overflow-y-scroll mx-auto bg-white"
      size="955px"
    >
      <Box className="flex flex-col w-full items-center gap-y-16 p-20">
        <Box className="flex flex-row justify-center">
          <MdWarning size={24} color={COLOR_NOTIFICATION} />
          <Text className="text-primary-2 font-bold text-lg ">
            【Gmailをお使いの方へ】大切なメールをメインタブで受信する方法
          </Text>
        </Box>
        <Box>
          <Text className="text-base text-black-1">
            Gmailをお使いの場合、受信したメールが自動的に「プロモーション」や「フォーラム」のタブに分類されてしまうことがございます。外資就活ドットコムから送信される「募集開始のお知らせ」や「イベントのお知らせ」など、大切なメールを見逃してしまうことにつながります。もし外資就活ドットコムからのメールが「プロモーション」や「フォーラム」に分類されている場合、以下のような設定を推奨しております。
          </Text>
        </Box>
        <Box className="flex flex-col gap-y-8 w-full">
          <Text className="font-bold text-lg text-black-1">Gmail設定手順</Text>
          <Text className="text-base text-black-1">
            1.
            「プロモーション」のタブを開き、外資就活ドットコムから送信されたメールをクリックしたまま「メイン」タブに移動させてください。
          </Text>
          <Image src={GMAIL_PC_MBOX} />
          <Text className="text-base text-black-1">
            2.
            メールをメインタブに移動させると、以下のようなメッセージが表示されますので「はい」をクリックしてください。設定後は同じメールアドレスからのメールは「メイン」タブに分類されるようになります。
          </Text>
          <Image src={GMAIL_PC_IMAGE} />
          <Text className="text-base text-black-1">
            企業から送信されるメールにつきましても、「プロモーション」や「フォーラム」に分類されていたり、迷惑メールボックスに入ってしまうことがありますので、大切なメールを見落とさないように定期的にチェックをしておきましょう！
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};

import Bugsnag from '@bugsnag/js';
import getConfig from 'next/config';

export function bugsnag() {
  const {publicRuntimeConfig} = getConfig();

  // Next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE

  if (process.env.NEXT_PHASE === 'phase-production-build') {
    return;
  }

  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
    appVersion: publicRuntimeConfig.version,
    releaseStage: process.env.NEXT_PUBLIC_RELEASE_STAGE,
    plugins: [],
  });
}

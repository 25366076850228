import {z} from 'zod';
import {MessageTypesEnum, ScoutStatusEnum, UserTypesEnum} from './constants';

export const Company = z.object({
  ID: z.number(),
  name: z.string(),
  LogoURL: z.string(),
});

export const ScoutType = z.object({
  id: z.number(),
  name: z.string(),
});

export const ChatRoom = z.object({
  id: z.number(),
  lastMessage: z.string().optional(),
});

export const Scout = z.object({
  id: z.number(),
  messageID: z.number(),
  title: z.string(),
  message: z.string(),
  status: ScoutStatusEnum,
  deadline: z.string(),
  created: z.string(),
  scoutType: ScoutType,
  company: Company,
  chatRoom: ChatRoom.optional(),
  canChat: z.boolean(),
});

export const ScoutList = z.array(Scout);

export const GetScoutResponse = z.object({
  scout: Scout,
});

export const GetScoutsResponse = z.object({
  scouts: ScoutList,
  totalCount: z.number(),
  nextPage: z.number(),
});

export const ChatMessage = z.object({
  id: z.number(),
  userType: UserTypesEnum,
  userID: z.number(),
  messageType: MessageTypesEnum,
  body: z.string(),
  postedAt: z.string(),
});

export const ChatMessageList = z.array(ChatMessage).nullable();

export const GetChatMessagesResponse = z.object({
  lastReadAt: z.string(),
  chatMessages: ChatMessageList,
});

export const PostChatMessagesResponse = z.object({
  chatMessage: ChatMessage,
});

export const ScoutMessageStatusResponse = z.string();

export const GetUnreadScoutMessagesCountResponse = z.object({
  count: z.number(),
});

export const Unnotified = z.object({
  scoutID: z.number(),
  deadline: z.string(),
});

export const unnotifieds = z.array(Unnotified);

export const GetUnnotifiedResponse = z.object({
  unnotifieds,
});

export type GetUnnotifiedType = z.infer<typeof GetUnnotifiedResponse>;

// Users/me/scout-messages/notified-logs
export const PutNotifiedLogsData = z.object({
  scout_ids: z.array(z.number()),
  ignored: z.number(),
});

export const NotifiedLogSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  scout_id: z.number(),
  scout_status: z.string(),
  ignored: z.boolean(),
});

export const PutNotifiedLogsResponse = z.object({
  logs: z.array(NotifiedLogSchema),
});

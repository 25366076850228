import React, {useState} from 'react';
import {Box, Modal, Text, Image} from '@mantine/core';
import {MdClose, MdWarning} from 'react-icons/md';
import {COLOR_NOTIFICATION} from '../../../config/constants/colorCodes';
import {GMAIL_PC_IMAGE, GMAIL_PC_MBOX} from '../../../config/constants/img';
import {useMediaQuery} from '../../../shared/services/useMediaQuery';
import {GmailModalPc} from './Container/GmailModalPc';
import {GmailModalSp} from './Container/GmailModalSp';

type Props = {
  closePopup: () => void;
};
export const GmailPromo: React.FC<Props> = ({closePopup}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {isPC} = useMediaQuery();

  return (
    <>
      {isPC ? (
        <>
          <Box className="flex flex-row justify-between">
            <Box className="flex flex-row gap-x-4">
              <MdWarning size={18} color={COLOR_NOTIFICATION} />
              <Box className="text-base text-black-1 flex flex-row">
                【Gmailをお使いの方へ】大切なお知らせを見逃している可能性があります。
                <Text
                  className="text-primary-2 cursor-pointer"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  詳しく見る
                </Text>
              </Box>
            </Box>
            <Box
              className="flex flex-row text-primary-2 items-center text-base cursor-pointer"
              onClick={closePopup}
            >
              <MdClose size={14} />
              <Text>閉じる</Text>
            </Box>
          </Box>
          <GmailModalPc
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
          />
        </>
      ) : (
        <Box className="flex flex-row justify-between">
          <Box
            className="flex flex-row gap-x-4 text-primary-1 items-center"
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            <MdWarning size={18} />
            <Box className="text-lg  flex flex-row cursor-pointer">
              Gmailをお使いの方へ
            </Box>
          </Box>
          <Box
            className="flex flex-row text-primary-2 items-center text-base cursor-pointer"
            onClick={closePopup}
          >
            <MdClose size={14} />
          </Box>
          <GmailModalSp
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            closePopup={closePopup}
          />
        </Box>
      )}
    </>
  );
};

import {useRouter} from 'next/router';
import {
  LP_TO_LIIGA_ACCOUNT,
  REPORT_ANALYTICS_RESULT,
  REPORT_ANALYTICS_SEARCH,
} from '../../config/constants/routes';

export function checkPathForPopup() {
  const router = useRouter();
  const noPopupUrls = [
    LP_TO_LIIGA_ACCOUNT,
    REPORT_ANALYTICS_SEARCH,
    REPORT_ANALYTICS_RESULT,
  ];
  const currentUrl = router.pathname;
  for (const url of noPopupUrls) {
    if (currentUrl.includes(url)) return false;
  }

  return true;
}

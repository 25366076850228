import React from 'react';
import {Box, Modal, Text, Image} from '@mantine/core';
import {MdWarning} from 'react-icons/md';
import {BasicButtonThin} from '@packages/ui';
import {
  GMAIL_SP_1,
  GMAIL_SP_2,
  GMAIL_SP_3,
} from '../../../../config/constants/img';
import {COLOR_NOTIFICATION} from '../../../../config/constants/colorCodes';

type Props = {
  isOpenModal: boolean;
  setIsOpenModal: (isOpen: boolean) => void;
  closePopup: () => void;
};

export const GmailModalSp: React.FC<Props> = ({
  isOpenModal,
  setIsOpenModal,
  closePopup,
}) => {
  return (
    <Modal
      opened={isOpenModal}
      onClose={() => {
        setIsOpenModal(false);
      }}
      withCloseButton
      className="h-[90%] relative m-auto overflow-y-scroll mx-auto bg-white"
      size="100%"
    >
      <Box className="flex flex-col w-full items-center gap-y-16">
        <Box className="flex flex-rowr justify-start items-center">
          <MdWarning size={20} color={COLOR_NOTIFICATION} />
          <Text className="text-primary-2 font-bold text-md">
            大切なメールを見逃さないために
          </Text>
        </Box>
        <Box>
          <Text className="text-sm text-black-1">
            Gmailをお使いの場合、外資就活ドットコムからのメールが自動的に「プロモーション」「フォーラム」に分類されてしまうことがあります。大切なメールを見逃さないために「プロモーション」や「フォーラム」ではなく「メイン」に分類されるように、以下の設定をおすすめします。
          </Text>
        </Box>
        <Box className="flex flex-col gap-y-8 w-full">
          <Text className="text-sm text-black-1">【Gmail設定手順】</Text>
          <Text className="text-sm text-black-1">
            「プロモーション」を開き、外資就活ドットコムから送信されたメールを選択し「メイン」に移動してください。
          </Text>
          <Image src={GMAIL_SP_1} />
          <Image src={GMAIL_SP_2} />
          <Image src={GMAIL_SP_3} />
          <Text className="text-sm text-black-1">
            移動すると、今後同じメールアドレスからのメールは「メイン」で受信することができるようになります。
          </Text>
        </Box>
        <BasicButtonThin
          onClick={() => {
            setIsOpenModal(false);
            closePopup();
          }}
          className="w-full"
          label="次回から表示しない"
          isPrimary={true}
        />
      </Box>
    </Modal>
  );
};

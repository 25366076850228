const isAppWebView = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const ua = window.navigator.userAgent;
  return (
    ua.indexOf('Gaishishukatsu iOS App') > 0 ||
    ua.indexOf('Gaishishukatsu Android App') > 0
  );
};

export default isAppWebView;

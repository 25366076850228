import React from 'react';
import {Box, Modal, Text, Image} from '@mantine/core';
import {
  SCOUT_FLOW_IMAGE_1,
  SCOUT_FLOW_IMAGE_2,
  SCOUT_FLOW_IMAGE_3,
  SCOUT_FLOW_IMAGE_4,
  SCOUT_IMAGE_1,
} from '../../../../config/constants/img';
import {useMediaQuery} from '../../../../shared/services/useMediaQuery';

type Props = {
  isOpenModal: boolean;
  setIsOpenModal: (isOpen: boolean) => void;
};

export const ScoutModal: React.FC<Props> = ({isOpenModal, setIsOpenModal}) => {
  const {isPC} = useMediaQuery();
  return (
    <Modal
      opened={isOpenModal}
      onClose={() => {
        setIsOpenModal(false);
      }}
      withCloseButton
      className="h-[90%] relative m-auto overflow-y-scroll mx-auto bg-white"
      size="955px"
      title={
        <Box className="pl-12 text-xl text-primary-2 font-bold">
          Premiumスカウトとは
        </Box>
      }
    >
      <Box className="flex flex-col pc:w-[90%] mx-auto items-center gap-y-16 pc:py-20 pc:px-[40px]">
        {isPC ? (
          <Image src={SCOUT_IMAGE_1} />
        ) : (
          <Image src={SCOUT_IMAGE_1} width={416} height={139} />
        )}
        <Box className="flex flex-row justify-center">
          <Text className="text-base text-black-1">
            Premiumスカウトとは、外資就活ドットコムが厳選した優良企業から、あなただけに特別なスカウトが届くサービスです。
            学生が登録したプロフィールを見た各企業の担当者が、気になる学生一人ひとりに向けてスカウトを丁寧に送ります。
            スカウトの内容は、気軽なランチのお誘いから選考面接までさまざま。
            通常の長い選考フローとは異なり、企業によっては面倒な採用ステップを飛ばしてくれるかもしれません。
            ときには役員面接から始まるなんてことも……!?
          </Text>
        </Box>
        <Box className="flex flex-col border border-primary-1 p-8 w-full rounded-12">
          <Box className="bg-primary-1 w-full items-center  mb-12">
            <Text className="text-white font-bold text-center text-lg">
              こんな人にオススメ!!
            </Text>
          </Box>
          <Box className="flex flex-col items-center gap-y-8 text-black-1 font-bold">
            <Text className="text-base">
              ・地方や海外在住なので、都内に何度も足を運びたくない
            </Text>
            <Text className="text-base">
              ・研究や部活が忙しくて、就職活動に十分な時間が割けない
            </Text>
            <Text className="text-base">・自分を必要とする企業を知りたい</Text>
          </Box>
        </Box>
        <Box className="border-b w-full border-black-1">
          <Text className="text-lg text-primary text-start font-bold">
            スカウト成立までの流れ
          </Text>
        </Box>
        <Box className="flex flex-col">
          <Box className="flex flex-col pc:flex-row gap-x-8 justify-center items-center gap-y-8">
            {isPC ? (
              <>
                <Box className="flex flex-col items-center gap-y-8">
                  <Image src={SCOUT_FLOW_IMAGE_1} />
                  <Box className="text-white font-bold text-base text-center py-4 px-16 bg-primary-1">
                    より多くのスカウトが届くように
                    プロフィールを充実させましょう
                  </Box>
                </Box>
                <Box className="flex flex-col items-center gap-y-8">
                  <Image src={SCOUT_FLOW_IMAGE_2} />
                  <Box className="text-white font-bold text-base text-center py-4 px-16 bg-primary-1">
                    あなたに興味をもった企業から スカウトが届きます
                  </Box>
                </Box>
                <Box className="flex flex-col items-center gap-y-8">
                  <Image src={SCOUT_FLOW_IMAGE_3} />
                  <Box className="text-white font-bold text-base text-center py-4 px-16 bg-primary-1">
                    スカウトが気になったら <br />
                    早速『承諾する』ボタンをClick!!
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box className="flex flex-col items-center gap-y-8 w-[324px]">
                  <Image src={SCOUT_FLOW_IMAGE_1} width={324} height={234} />
                  <Box className="text-white font-bold text-sm text-center py-4 px-16 bg-primary-1 w-full">
                    より多くのスカウトが届くように
                    <br />
                    プロフィールを充実させましょう
                  </Box>
                </Box>
                <Box className="flex flex-col items-center gap-y-8 w-[324px]">
                  <Image src={SCOUT_FLOW_IMAGE_2} width={324} height={234} />
                  <Box className="text-white font-bold text-sm text-center py-4 px-16 bg-primary-1 w-full">
                    あなたに興味をもった企業から
                    <br />
                    スカウトが届きます
                  </Box>
                </Box>
                <Box className="flex flex-col items-center gap-y-8 w-[324px]">
                  <Image src={SCOUT_FLOW_IMAGE_3} width={324} height={234} />
                  <Box className="text-white font-bold text-sm text-center py-4 px-16 bg-primary-1 w-full">
                    スカウトが気になったら <br />
                    早速『承諾する』ボタンをClick!!
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box className="flex justify-center">
            {isPC ? (
              <Image src={SCOUT_FLOW_IMAGE_4} />
            ) : (
              <Image src={SCOUT_FLOW_IMAGE_4} width={624} height={312} />
            )}
          </Box>
        </Box>
        <Box className="border-b w-full border-black-1">
          <Text className="text-lg text-primary text-start font-bold">FAQ</Text>
        </Box>
        <Box className="border-b w-full py-4 flex flex-col">
          <Box className="flex flex-row gap-x-12 text-base pc:text-md">
            <Text className=" text-primary text-start font-bold">Q.</Text>
            <Text className=" text-black-1 font-bold">
              プロフィールデータはどこまで企業に公開されるの？
            </Text>
          </Box>
          <Box className="p-12 flex flex-col pc:text-base text-black font-bold text-sm">
            <Text>
              企業が学生を検索する場合、
              氏名、電話番号、メールアドレス、生年月日は一切公開されません。
            </Text>
            <Text>
              インターンシップ経験先は、社名まで公開されます。公開したくない場合は入力をお控えください。
            </Text>
            <Text>内定先は公開されません。</Text>
          </Box>
        </Box>
        <Box className="border-b w-full py-4 flex flex-col">
          <Box className="flex flex-row gap-x-12 text-base pc:text-md">
            <Text className="text-primary text-start font-bold">Q.</Text>
            <Text className="text-black-1 font-bold">
              『承諾する』ボタンを押したらどうなるの？
            </Text>
          </Box>
          <Box className="p-12 flex flex-col pc:text-base text-black font-bold text-sm">
            <Text>
              「気になる」ボタンを押すと、スカウトを送った企業にのみ、氏名とメールアドレスと電話番号が公開されます。
            </Text>
            <Text>
              あなたのメールアドレス宛に企業からメッセージが届くと思いますので、
            </Text>
            <Text>あとはメールにてメッセージのやりとりを行ってください。</Text>
          </Box>
        </Box>
        <Box className="border-b w-full py-4 flex flex-col">
          <Box className="flex flex-row gap-x-12 text-base pc:text-md">
            <Text className="text-primary text-start font-bold">Q.</Text>
            <Text className="text-black-1 font-bold">
              「拒否する」ボタンを押したら企業にバレる？悪印象を残してしまわないか不安です。
            </Text>
          </Box>
          <Box className="p-12 flex flex-col pc:text-base text-black font-bold text-sm">
            <Text>「拒否する」ボタンを押しても企業側には通知されません。</Text>
            <Text>ただし、「拒否する」ボタンを押してしまうと、</Text>
            <Text>
              そのスカウトの「承諾する」ボタンを押すことができなくなってしまうので、お気をつけください。
            </Text>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export const COLOR_PRIMARY = '#115174';
export const COLOR_PRIMARY_1 = '#1E6A93';
export const COLOR_PRIMARY_2 = '#4F9DC5';
export const COLOR_PRIMARY_3 = '#74ADCC';

export const COLOR_LINK_1 = '#1E83B9';

export const COLOR_BACKGROUND_1 = '#F5F5F5';
export const COLOR_BACKGROUND_2 = '#EEEEEE';

export const COLOR_LINE_1 = '#CFD0D7';
export const COLOR_LINE_2 = 'rgba(0, 0, 0, 0.16)';

export const COLOR_BLACK = '#050505';
export const COLOR_BLACK_1 = '#4D4D4D';
export const COLOR_BLACK_2 = '#777777';
export const COLOR_BLACK_3 = '#A0A0A0';
export const COLOR_BLACK_4 = '#B5B5B5';

export const COLOR_WHITE = '#FFFFFF';

export const COLOR_SUCCESS_1 = '#439B96';
export const COLOR_SUCCESS_2 = 'rgba(67, 155, 150, 0.9)';
export const COLOR_ERROR_1 = '#F57B73';
export const COLOR_ERROR_2 = 'rgba(245, 123, 115, 0.9)';

export const COLOR_SUB = '#EDB835';
export const COLOR_TABLE_HEADER = '#a58e22';

export const COLOR_OVERRAY = 'rgba(0, 0, 0, 0.48)';

export const COLOR_CATEGORY_2 = '#EECE95';
export const COLOR_CATEGORY_5 = '#8299CA';
export const COLOR_CATEGORY_10 = '#8DD28E';

export const COLOR_NOTIFICATION = '#F44336';

export const COLOR_AI_BACKGROUND = '#F0EEF6';
export const COLOR_AI_GRADIENT_START = '#676CFA';
export const COLOR_AI_GRADIENT_MIDDLE = '#7D97F5';
export const COLOR_AI_GRADIENT_END = '#BF8CFF';
export const COLOR_NOTIFICATION_1 = '#F44336';

import {useMediaQuery as useMantineMediaQuery} from '@mantine/hooks';

export const useMediaQuery = () => {
  const isPC = useMantineMediaQuery('(min-width: 668px)');
  const isLargeScreen = useMantineMediaQuery('(min-width: 1100px)');
  return {
    isPC,
    isLargeScreen,
  };
};

import * as React from 'react';
import {ClientContext} from './ClientContext';
import {ClientContextClass} from './ClientContextClass';
import {type ClientContextInterface} from './ClientContextInterface';

export type ClientProviderProps = {
  children: React.ReactElement;
  client?: ClientContextInterface;
};

export const ClientProvider: React.FC<ClientProviderProps> = ({
  children,
  client,
}) => {
  const defaultClient = new ClientContextClass();
  return (
    <ClientContext.Provider value={client ?? defaultClient}>
      {children}
    </ClientContext.Provider>
  );
};
